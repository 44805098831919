import styled, { css } from "styled-components";

// assets
import DiscordIcon from "../../assets/Socials/Discord";
import GithubIcon from "../../assets/Socials/Github";

function Learn(props) {
  return (
    <Header id="Doc">
      <Container>
        <Row>
          <PreTitle>Documentation</PreTitle>
          <StyledText>Saffron Development</StyledText>
          <Row alt>
            <A
              href="https://github.com/saffron-finance/saffron"
              target="_blank"
            >
              <GithubIcon mr={"30px"} width={"50px"} />
            </A>
            <A href="https://discord.gg/H7TxUEp3wj" target="_blank">
              <DiscordIcon width={"50px"} />
            </A>
          </Row>
        </Row>
        <HeaderDiv>
          <SubText>Dive into Saffron Technology.</SubText>
          <AnchorRow>
            {/* <A href="https://docs.saffron.finance/saffron-finance/whitepaper" target="_blank">
              Whitepaper
            </A> */}
            <A href="https://docs.saffron.finance/" target="_blank">
              Documentation
            </A>
          </AnchorRow>
        </HeaderDiv>
      </Container>
    </Header>
  );
}

export default Learn;

const Header = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding-top: 180px;
  @media only screen and (max-width: 1022px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.cardColor};
  border-radius: 10px;
  padding: 25px;
  @media only screen and (max-width: 1070px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 1328px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.alt &&
    css`
      flex-direction: row;
      margin-top: 100px;
      @media only screen and (max-width: 1070px) {
        justify-content: center;
        align-items: center;
        padding-left: 0px;
        margin-top: 50px;
      }
    `}
  @media only screen and (max-width: 1306px) {
    padding-left: 15px;
  }
  @media only screen and (max-width: 1070px) {
    padding-left: 0px;
  }
`;

const HeaderDiv = styled.div`
  @media only screen and (max-width: 1306px) {
    padding-right: 15px;
  }
  @media only screen and (max-width: 1070px) {
    padding-right: 0px;
    padding-top: 50px;
  }
`;


const PreTitle = styled.span`
  color: ${(props) => props.theme.fontColorAlt};
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
`;

const StyledText = styled.h1`
  color: ${(props) => props.theme.fontColor};
  font-weight: 500;
  font-size: 38px;
  margin: 0;
`;

const SubText = styled.p`
  color: ${(props) => props.theme.fontColorAlt};
  text-align: left;
  width: 650px;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  ${(props) =>
    props.alt &&
    css`
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      width: 300px;
    `}
  @media only screen and (max-width: 1283px) {
    width: auto;
  }
  @media only screen and (max-width: 1070px) {
    text-align: center;
  }
  @media only screen and (max-width: 622px) {
    width: auto;
    padding: 0px 15px 0px 15px;
  }
`;

const AnchorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 45px;
  @media only screen and (max-width: 1070px) {
    justify-content: center;
  }
`;

const A = styled.a`
  color: #c44536;
  cursor: pointer;
  text-decoration: underline;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
  ${(props) =>
    props.alt &&
    css`
      margin-left: 45px;
    `}
`;
