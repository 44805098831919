import React from "react";
import styled from "styled-components";

const KebabIcon = () => {
  return (
    <Div>
      <svg
        width="21"
        height="5"
        viewBox="0 0 21 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Circle cx="2.5" cy="2.5" r="2.5" transform="rotate(-90 2.5 2.5)" />
        <Circle cx="10.5" cy="2.5" r="2.5" transform="rotate(-90 10.5 2.5)" />
        <Circle cx="18.5" cy="2.5" r="2.5" transform="rotate(-90 18.5 2.5)" />
      </svg>
    </Div>
  );
};

export default KebabIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.circle`
  fill: ${(props) => props.theme.fontColorAlt};
`;
